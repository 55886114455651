.App {
  background-color: #282c34;
  color: white;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
}

.App a {
  color: white;
}

.App-container {
  height: calc(100vh - 250px);
  margin: 0px auto;
  padding: 25px;
  width: 1000px;
}

.App-header {
  align-items: center;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  padding: 25px;
  width: 1000px;
}

.App-header .location-input {
  background-color: #282c34;
  border: 1px solid #ccc;
  color: white;
  font-size: calc(10px + 2vmin);
  padding: 8px;
}

.App-header .location-input:focus {
  outline: none;
}

.App-footer {
  margin: 0px auto;
  padding: 25px;
  text-align: center;
  width: 1000px;
}


.dialog-todos {
  padding: 20px;
  position: relative;
  height: 165px;
}

.dialog-todos input.todo-input {
  background: none;
  border: 1px solid white;
  bottom: 20px;
  color: white;
  height: 24px;
  left: -10px;
  outline: none;
  padding: 2px 8px;
  font-size: 14px;
  position: absolute;
  width: 100%;
}
