.dialog {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.dialog-container {
  background-color: #282c34;
  box-shadow: 0px 0px 2px 5px rgba(255, 255, 255, .2);
  font-size: 14px;
  height: 200px;
  margin: 25% auto;
  padding: 20px;
  position: relative;
  width: 300px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
  border: none;
  background: none;
  border-radius: 25px;
  width: 35px;
  height: 35px;
}

.close:hover,
.close:focus {
  color: #000;
  background-color: #fafafa;
  text-decoration: none;
  cursor: pointer;
}
