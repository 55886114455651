.Calendar-container {
  height: 100%;
}

.Calendar-container > div {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid black;
}

.Calendar-container .Calendar-month {
  padding: 8px 12px;
}

.Calendar-month button {
  background: none;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  padding: 4px 12px;
  text-align: center;
  width: 200px;
}

.Calendar-month span{
  text-align: center;
  width: 400px;
}

.Calendar-container .Calendar-table {
  border: none;
  flex-direction: column;
  height: 100%;
  /* height: 75vh; */
}

.Calendar-table table {
  border-collapse: collapse;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}

.Calendar-table table thead tr {
  border-bottom: 1px solid black;
  padding: 12px 0px;
}

.Calendar-table table div {
  font-size: 16px;
}

.Calendar-table table thead div {
  text-align: center;
}

.Calendar-table table tbody tr {

}

.Calendar-table table tbody tr td {
  padding: 8px;
}

.Calendar-table table tbody td.grayedout {
  color: #666;
}
.Calendar-table table tbody td.today .date-display span {
  border-radius: 21px;
  padding: 5px;
  background-color: black;
}

.Calendar-table table tbody tr:not(:last-child) td {
  border-bottom: 1px solid black;
}

.Calendar-table table tbody tr td:not(:last-child) {
  border-right: 1px solid black;
}

.Calendar-table .day-header {
  height: 20px;
  position: relative;
}

.Calendar-table .day-header .date-display {
  position: absolute;
  text-align: center;
  width: 100%;
}

.Calendar-table .day-header .weather-display {
  position: absolute;
  right: 0;
  font-size: 13px;
}


.Calendar-table table tbody tr td:hover {
  background-color: #1c2124;
}

.Calendar-table table tbody tr td > div {
  height: 100%;
}

.Calendar-table .day-body {
  height: calc(100% - 20px);
  overflow: auto;
}

.todos .add-todo {
  display: flex;
  height: 25px;
  justify-content: center;
}

.todos .done {
  text-decoration: line-through;
}

.todos .add-todo > button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: none;
  font-size: 16px;
  height: 25px;
  padding: 5px;
  width: 25px;
}

.Calendar-table table tbody tr td:hover .todos .add-todo > button {
  display: block;
}

.Calendar-table .todos .add-todo > button:hover {
  background-color: #333;
  border-radius: 15px;
  padding: 5px;
}

.Calendar-table .todos .todos-list {
  height: 5vh;
  overflow: auto;
}

.Calendar-table .todos .todos-list .todo-item label {
  font-size: 12px;
}

.Calendar-table .todos .todos-list .todo-item {
  align-items: center;
  display: flex;
}
